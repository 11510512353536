import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

import { Container } from "../global"

const Footer = () => (
  <FooterWrapper id="footer">
    <FooterColumnContainer>
      <FooterColumn>
        <span>Contacts</span>
        <ul>
          <li><RefLink href="mailto:contact@hedera.in">contact@hedera.in</RefLink></li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Links</span>
        <ul>
          <li><RefLink href="https://hub31.com" target="_blank" rel="noopener noreferrer">Hub31.com</RefLink></li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Company</span>
        <ul>
          <li><HederaLink to="/about">About Us</HederaLink></li>
          <li><HederaLink to="/careers">Careers</HederaLink></li>
        </ul>
      </FooterColumn>
      <FooterColumn>
        <span>Social</span>
        <ul>
          <li><RefLink href="https://www.linkedin.com/company/in-hedera" target="_blank" rel="noopener noreferrer">LinkedIn</RefLink></li>
          <li><RefLink href="https://www.facebook.com/hedera.in" target="_blank" rel="noopener noreferrer">Facebook</RefLink></li>
        </ul>
      </FooterColumn>
    </FooterColumnContainer>
    <BrandContainer>
      <Logo>Hedera Tech</Logo>
      <Copyright>Copyright © 2021 Hedera Tech. All rights reserved.</Copyright>
    </BrandContainer>
  </FooterWrapper>
)

const FooterWrapper = styled.footer`
  background-color: white;
  margin: 80px 0 0;
  padding: 0 0 80px;
`

const Logo = styled.div`
  font-weight: ${props => props.theme.font_weight.extrabold};
  ${props => props.theme.font_size.regular};
  color: ${props => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 9;
  text-decoration: none;
  outline: 0px;
`
const Copyright = styled.div`

`
const BrandContainer = styled(Container)`
  padding-top: 48px;

  @media (max-width: ${props => props.theme.screen.sm}) {
  }
`
const FooterColumnContainer = styled(Container)`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 32px;
  justify-content: start;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr 1fr;
    grid-gap: 32px;
  }
`
const FooterColumn = styled.div`
  span {
    font-size: 16px;
    font-weight: ${props => props.theme.font_weight.bold};
    color: ${props => props.theme.color.primary};
  }
  ul {
    list-style: none;
    margin: 16px 0;
    padding: 0;
    color: ${props => props.theme.color.black.regular};
    li {
      margin-bottom: 12px;
      font-weight: ${props => props.theme.font_weight.normal};
      font-size: 15px;
    }
  }
`
const RefLink = styled.a`
  text-decoration: none;
`

const HederaLink = styled(Link)`
  text-decoration: none;
`
export default Footer
