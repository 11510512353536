import React, { useState, useEffect } from "react"
import { Link } from "gatsby"
import styled from "styled-components"

import { Container } from "../../global"
import hedlogo from '../../../images/hedlogo.svg';
import {
  Nav,
  NavItem,
  Brand,
  StyledContainer,
  NavListWrapper,
  MobileMenu,
  Mobile,
  ActionsContainer,
  HedLogo
} from "./style"

const NAV_ITEMS = ["Features", "Usecases", "Pricing", "Contact"]

const Navigation = (props) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [hasScrolled, sethasScrolled] = useState(false);

  useEffect(() => {
    function handleScroll(event) {
      const scrollTop = window.pageYOffset

      if (scrollTop > 32) {
        sethasScrolled(true)
      } else {
        sethasScrolled(false)
      }
    }
    window.addEventListener("scroll", handleScroll)
    return () => window.removeEventListener("scroll", handleScroll)
  }, []);

  const getNavList = ({ mobile = false }) => (
    <NavListWrapper mobile={mobile}>
      <ul>
        {NAV_ITEMS.map(navItem => (
          <NavItem key={navItem}>{getNavLink(navItem)}</NavItem>
        ))}
      </ul>
    </NavListWrapper>
  )

  const getNavLink = item => (
    <Link to={`/${item.toLowerCase()}`} onClick={() => setMobileMenuOpen(false)} activeClassName="active">
      {item.toUpperCase()}
    </Link>
  )

  return (
    <Nav {...props} scrolled={hasScrolled}>
      <StyledContainer>
        <Brand>
          <Link to="/" onClick={() => setMobileMenuOpen(false)}>
            <HedLogo src={hedlogo} alt="Hedera" title="Hedera" />
          </Link>

        </Brand>
        <Mobile>
          <button
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
            style={{ color: "black", background: "none" }}
          >
            {mobileMenuOpen ? (
              <MenuIcon className="material-icons md-24" alt="close menu">close</MenuIcon>
            ) : (
                <MenuIcon className="material-icons md-24" alt="open menu">menu</MenuIcon>
              )}
          </button>
        </Mobile>

        <Mobile hide>{getNavList({})}</Mobile>
        {/* <ActionsContainer>
            <button>Contact</button>
          </ActionsContainer> */}
      </StyledContainer>
      <Mobile>
        {mobileMenuOpen && (
          <MobileMenu>
            <Container>{getNavList({ mobile: true })}</Container>
          </MobileMenu>
        )}
      </Mobile>
    </Nav>
  );
}

export default Navigation;

const MenuIcon = styled.i``;
